import React from 'react'
import HeroWide from '@widgets/HeroWide'
import Content from './Hero.Content'
import Photo from './Hero.Photo'
import { Main, Sidebar } from '@layout'
import CardList from "@components/CardList";

const Hero = props => {
  const {
    location: { pathname },
    pageContext: { basePath },
    other: { featuredPosts, recentPosts },
  } = props

  if (pathname !== basePath) return null

  return (
    <HeroWide.Wrapper sx={{ pb: 0, pt: 5 }}>
        <Main>
        <CardList
            variant={['vertical-cover']}
            title='Featured this month'
            limit={4}
            nodes={featuredPosts.nodes}
            columns={[1, 2]}
            loading='eager'
            slider
            autoPlay
        />
        </Main>
        <Sidebar>
            <CardList
                nodes={featuredPosts.nodes}
                limit={4}
                skip={4}
                variant='horizontal-aside'
                title='Popular'
                omitMedia
                omitCategory
                aside
            />
        </Sidebar>
      {/*<HeroWide.LeftColumn>*/}
      {/*  <Content />*/}
      {/*</HeroWide.LeftColumn>*/}
      {/*<HeroWide.RightColumn>*/}
      {/*  <Photo />*/}
      {/*</HeroWide.RightColumn>*/}
    </HeroWide.Wrapper>
  )
}

export default Hero
